import { inject, ref, readonly, computed } from 'vue';

import {
  fetchPageBuilder,
  setItemKey,
  getItemKey,
  invokeKey,
} from '@drapejs/core';

import { commands } from '@/../connectors/litium/giftcard';
import { format } from 'date-fns';
import useContext from '@/composables/useContext';

const giftCardState = ref();
const wizardSettings = ref<any>({});
const requestPending = ref(false);

const settingsFetched = ref(false);

export default function () {
  const setItem = inject(setItemKey, async () => {});
  const getItem = inject(getItemKey, async () => null);
  const invoke = inject(invokeKey, async () => null);

  const { isAuthorized, route, navigateToLoginPage } = useContext();

  const selectedVariant = computed(() => {
    return (
      (giftCardState.value?.settings?.giftCardTypes || []).find(
        (e: any) => e.isSelected
      )?.variants || []
    ).find((e: any) => e.isSelected);
  });

  async function setGiftCardLanguage(locale: string) {
    wizardSettings.value.locale = locale;
    await fetchEditorSettings();
  }

  async function setGiftCardVariant(variantId: string) {
    wizardSettings.value.variantId = variantId;
    await fetchEditorSettings();
  }

  async function setGiftCardQuantity(quantity: number) {
    wizardSettings.value.quantity = quantity;
    await fetchEditorSettings();
  }

  async function setWizardConfigured() {
    wizardSettings.value.isConfigured = true;
    if (route.query.id) {
      await setItem(
        getGiftCardSettingsCacheKey(wizardSettings.value.giftCardId),
        {
          ...wizardSettings.value,
        }
      );
    }
  }

  async function fetchEditorSettings() {
    if (!isAuthorized()) {
      await navigateToLoginPage();
      return;
    }

    const request = buildCommandRequest({
      type: wizardSettings.value.type,
      variantId: wizardSettings.value?.variantId || '',
      locale: wizardSettings.value?.locale || '',
      quantity: wizardSettings.value?.quantity || 1,
      giftCardId: wizardSettings.value.giftCardId,
      organizationId: wizardSettings.value.organizationId,
    });

    const result = await executeGraphQlRequest(
      commands.fetchGiftCardEditor,
      request
    );

    if (route.query.id) {
      await setItem(getGiftCardSettingsCacheKey(request.giftCardId), {
        ...wizardSettings.value,
      });
    }

    giftCardState.value = result;
  }

  function buildCommandRequest(args: any) {
    return {
      ...fetchPageBuilder(
        route.protocol,
        route.host,
        route.pathname,
        {
          ...route.query,
        },
        ''
      ),
      ...args,
    };
  }

  async function executeGraphQlRequest(command: any, request: any) {
    try {
      requestPending.value = true;
      const response = await invoke(command, request);
      return response;
    } catch (err) {
      console.error(err);
    } finally {
      requestPending.value = false;
    }
  }

  async function init() {
    const organizationId =
      (await getItem('__user'))?.organization?.organizationId || '';
    if (!route.query.id && !organizationId) {
      await navigateToLoginPage();
      return;
    }
    const giftCardId = route.query.id || generateGiftCardId(organizationId);

    const settings = await getItem(getGiftCardSettingsCacheKey(giftCardId));
    if (settings) {
      wizardSettings.value = settings;
    }
    wizardSettings.value.type = route.query.type;
    wizardSettings.value.organizationId = organizationId;
    wizardSettings.value.giftCardId = giftCardId;

    await fetchEditorSettings();
    settingsFetched.value = true;
  }

  function reset() {
    settingsFetched.value = false;
  }

  return {
    wizardSettings: readonly(wizardSettings),
    giftCardState: readonly(giftCardState),
    selectedVariant: readonly(selectedVariant),
    requestPending: readonly(requestPending),
    settingsFetched: readonly(settingsFetched),
    setGiftCardLanguage,
    setGiftCardVariant,
    setGiftCardQuantity,
    setWizardConfigured,
    init,
    reset,
  };
}

function getGiftCardSettingsCacheKey(giftCardId: string) {
  return `giftcard:settings:${giftCardId}`;
}

export function generateGiftCardId(organizationId: string) {
  return `${organizationId}_${format(new Date(), 'yyyy_MM_dd_HH_mm_ss_tt')}`;
}
